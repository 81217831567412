import CoreScoreDial from '../charts/core-score-dial';
import $ from 'jquery';
import * as d3 from 'd3';

$(document).ready(function() {
  if ($('body.dashboard.index').length){
    // Create and render the Core Score Dials.
    d3.selectAll('.core-score-dial')
      .each(function () {
        new CoreScoreDial(this, $(this).data()['level']).render();
      });

  }
});