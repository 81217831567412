import constants from '../shared/constants';

$(document).ready(function() {
    const $assessmentForm = $('#assessment-form');

    if (!$assessmentForm.length) return;

    const formAction = $('.form-container').data('action');
    const formData = $('.form-container').data('form');
    const $form = $('.form-container form');
    const outerSortable = $form.find('.outer-sortable-container');
    const $addPageContainer = $('.add-page-container');
    const $questionSearchInput = $('#question-search-input');
    const $pageNameInput = $('#page-name-input');

    let assessmentQuestions = null;

    if (outerSortable.length) initOuterSortable();

    // prevent form submission on enter keypress
    $form.keypress(e => {
        if (e.keyCode === 13) return false;
    });

    // add page to sortable container on enter keypress
    $pageNameInput.keypress(e => {
        if (e.keyCode === 13) onAddPageClick();
    });

    // update page and question orders on form submission
    $form.submit(() => updateOrders());

    // handle page additions
    $('.add-page-button').on('click', () => onAddPageClick());

    /**
     * Initializes sortable functionality for main list
     */
    function initOuterSortable() {
        outerSortable.sortable({
            connectWith: '.sortable',
            placeholder: 'placeholder',
            tolerance: 'pointer',
            cursor: 'move',
            axis: 'y',
            start: (event, ui) => {
                ui.placeholder.height(ui.item.height());
            },
            remove: function(event, ui) {
                const { item } = ui;
                // prevent user from nesting page within a page
                if (item.hasClass('page')) {
                    $(this).sortable('cancel');
                    return false;
                }
            }
        });
    }

    /**
     * Invoked when user clicks on the add page button. If a page name has been specified,
     * we add a page with that name to the sortable container. Otherwise, we show an error
     * message
     */
    function onAddPageClick() {
        const pageName = $pageNameInput.val();
        // bail and show error if name isn't specified
        if (!pageName || !pageName.length) {
            if (!$addPageContainer.find('.helper-msg').length) {
                $addPageContainer.append('<span class="helper-msg">Please enter a page name</span>');
            }
            return;
        }

        $addPageContainer.find('.helper-msg').remove();
        addPage(pageName, true, null);
        $pageNameInput.val('');
    }

    /**
     * Builds and returns HTML string for a question
     *
     * @param {string} questionName:        question name
     * @param {number} questionId:          databased question id
     * @returns {string} HTML string
     */
    const questionHtml = (question) => {
        const { name, id, assessment_question_category, assessment_question_answers, assessment_question_type } = question;
        const { name: categoryName } = assessment_question_category;
        const { name: typeName } = assessment_question_type;
        let maxScore = 0;
        if (assessment_question_answers.length) {
            if (typeName === constants.SINGLE_SELECT) {
                maxScore = Math.max(...assessment_question_answers.map(i => i.score));
            }
            else if (typeName === constants.MULTIPLE_SELECT) {
                maxScore = assessment_question_answers.filter(i => i.score >= 0).reduce((sum, i) => (sum + i.score), 0)
            }
        }
        return `
<div class="question" data-category-name="${categoryName}" data-max-score="${maxScore}">
    <div class="question-header">
        <div class="question-name">${name}</div>
        <div class="question-details">${categoryName}: ${maxScore}</div>
        <i class="material-icons remove">clear</i>
    </div>
    <input class="question-id-input" type="number" name="" id="" value="${id}" style="display: none"/>
    <input class="question-name-input" type="text" name="" id="" value="${name}" style="display: none" />
    <input class="question-order-input" type="number" name="" id="" value="" style="display: none" />
</div>
`
    };

    /**
     * Builds and returns HTML string for a page
     *
     * @param {string} name:        page name
     * @returns {string} HTML string
     */
    function pageHtml(name) {
        const html = $(`
<div class="page">
    <div class="viewable">
        <div class="page-header">
            <span class="page-name"></span>
            <span class="page-summary"></span>
            <i class="material-icons remove">clear</i>
        </div>
        <div class="page-questions sortable"></div>
    </div> 
    <input class="page-order-input" type="number" id="" name="" value="" style="display: none" />
    <input class="page-user-created-input" type="number" id="" name="" value="" style="display: none" />
    <input class="page-name-input" type="text" id="" name="" value="${name}" style="display: none" />
    
</div>
`);
        html.find('.page-name').text(name);
        return html;
    }

    // retrieve all assessment questions, then rebuild the form if this is an edit
    // or a failed create
    getAssessmentQuestions(() => checkRebuild());

    /**
     * Retrieves all assessment questions. This is required to populate the question search
     * auto-complete input.
     *
     * @param {Function?} callback: optional callback to invoke once assessment questions are
     * retrieved
     */
    function getAssessmentQuestions(callback) {
        $.ajax({
            url: '/assessment_questions',
            type: 'GET',
            success: questions => {
                assessmentQuestions = questions;
                updateQuestionSearchData();
                if (callback) callback();
            },
            error: error => console.error(error)
        });
    }

    /**
     * Callback invoked on question search option select. Adds the selected question
     * to the form
     *
     * @param {string} selected:        the selected option (question name)
     * @returns {boolean}
     */
    function onQuestionSearchSelect(selected) {
        // add the question to the form
        const question = assessmentQuestions.find(i => i.name === selected);
        if (question) addQuestion(question);
        // clear the question search input
        $questionSearchInput.val('');
        return false;
    }

    /**
     * Updates question search auto-complete data.
     */
    function updateQuestionSearchData() {
        if (!$questionSearchInput || !$questionSearchInput.length) return;
        // find questions already included in form
        const exclude = questionsToExclude();
        $questionSearchInput.autocomplete({
            data: assessmentQuestions.reduce((accum, ques) => {
                // add question to select options if not already on form
                if (exclude.indexOf(ques.name) < 0) accum[ques.name] = null;
                return accum;
            }, {}),
            limit: 10,
            onAutocomplete: onQuestionSearchSelect
        })
    }

    /**
     * Updates order values for pages and questions. Should be invoked once, right before form
     * submission.
     */
    function updateOrders() {
        // wrap non-page questions in pages
        wrapQuestionsWithPages();

        const pages = outerSortable.find('.page');
        $.each(pages, function(pageIdx, page) {
            const pagePrefix = `[form][pages_attributes][${pageIdx + 1}]`;
            const pageOrderInput = $(page).children('.page-order-input');
            pageOrderInput.attr({name: `${pagePrefix}[order]`, id: `${pagePrefix}[order]`});
            pageOrderInput.val(pageIdx + 1);
            const pageUserDefinedInput = $(page).children('.page-user-created-input');
            pageUserDefinedInput.attr({name: `${pagePrefix}[user_created]`, id: `${pagePrefix}[user_created]`});
            const pageNameInput = $(page).children('.page-name-input');
            pageNameInput.attr({name: `${pagePrefix}[name]`, id: `${pagePrefix}[name]`});

            const pageQuestions = $(page).find('.question');
            $.each(pageQuestions, function(questionIdx, question) {
                const pageQuestionPrefix = pagePrefix + `[questions][${questionIdx + 1}]`;
                const pageQuesOrderInput = $(question).find('.question-order-input');
                pageQuesOrderInput.val(questionIdx + 1);
                pageQuesOrderInput.attr({name: `${pageQuestionPrefix}[order]`, id: `${pageQuestionPrefix}[order]`});
                const pageQuesIdInput = $(question).find('.question-id-input');
                pageQuesIdInput.attr({name: `${pageQuestionPrefix}[id]`, id: `${pageQuestionPrefix}[id]`});
                const pageQuesNameInput = $(question).find('.question-name-input');
                pageQuesNameInput.attr({name: `${pageQuestionPrefix}[name]`, id: `${pageQuestionPrefix}[name]`})
            });
        });
    }

    /**
     * Wraps each non-page question in a page. Part of serialization process for DB. Though not represented
     * in UI, every question must belong to a page. These pages are distinguished by a 'user_created' value
     * of false
     */
    function wrapQuestionsWithPages() {
        const questions = outerSortable.children('.question');
        $.each(questions, function(idx, elem) {
            const pq = $(elem).wrap('<div class="page"></div>');
            pq.wrap('<div class="page-questions sortable"></div>');
            pq.parent('.page-questions').after('<input class="page-order-input" type="number" id="" name="" value="" style="display: none" />');
            // user_created val needs to be false
            pq.parent('.page-questions').after('<input class="page-user-created-input" type="number" id="" name="" value="0" style="display: none" />');
            pq.parent('.page-questions').after('<input class="page-name-input" type="text" id="" name="" value="" style="display: none" />');
        });
    }

    /**
     * Determines whether or not we need to rebuild the form. Form should be rebuilt
     * on edit, and when validation fails during form creation
     */
    function checkRebuild() {
        if (formAction === 'edit' && formData) {
            rebuildForm();
        }
        else if (formAction === 'new') {
            const { errors, pages_attributes } = formData;
            if (errors && pages_attributes) {
                rebuildForm();
            }
        }
        updateQuestionSearchData();
        updateSummaryCategoryPoints();
        updatePageCategoryPoints();
        $assessmentForm.find('.loader').fadeOut('fast');
    }

    /**
     * Rebuilds sortable form. Should be invoked on form edit, or when user is redirected back to form
     * creation after validation errors
     */
    function rebuildForm() {
        const { pages_attributes } = formData;

        if (!pages_attributes) {
            return;
        }

        Object.values(pages_attributes).forEach(val => {
            const { name, questions } = val;
            let { user_created } = val;
            if (user_created === "0") user_created = false;
            if (user_created === "1") user_created = true;
            // if current page is user created, add new page to form
            if (!!user_created || Object.keys(questions).length > 1) {
                addPage(name, !!user_created, questions);
            }
            // otherwise just add the question
            else {
                if (questions) {
                    Object.values(questions).forEach(q => {
                        const ques = assessmentQuestions.find(i => i.id == q.id);
                        if (ques) addQuestion(ques);
                    });
                }
            }
        });
    }

    /**
     * Adds question to main sortable list
     *
     * @param {object} question:  question to add
     */
    function addQuestion(question) {
        const elem = $(questionHtml(question));
        deleteElemHandler(elem, '.question');
        outerSortable.append(elem);
        // update question search data so this question is excluded from list
        updateQuestionSearchData();
        updateSummaryCategoryPoints();
    }

    /**
     * Updates category point summary for the entire form
     */
    function updateSummaryCategoryPoints() {
        const summary = $assessmentForm.find('.summary');
        if (!summary.length) return;

        let list = summary.find('ul');

        if (!list.length) {
            list = $('<ul></ul>');
            summary.append(list);
        }

        const questions = outerSortable.find('.question');
        const categories = categoryPointSummaryForQuestions(questions);

        list.empty();

        Object.keys(categories).sort().forEach(key => {
            list.append(`<li>${key}: ${categories[key]}</li>`)
        });
    }

    /**
     * Updates category points for user-created pages in the form.
     */
    function updatePageCategoryPoints() {
        const pages = $assessmentForm.find('.page');

        $.each(pages, function(idx, val) {
            const questions = $(val).find('.question');
            if (!questions.length) {
                $(val).find('.page-summary').empty();
                return true;
            }

            const categories = categoryPointSummaryForQuestions(questions);

            const pageSummary = $(val).find('.page-summary');
            pageSummary.empty();

            const list = $('<ul></ul>');
            pageSummary.append(list);

            Object.keys(categories).sort().forEach(key => {
                list.append(`<li>${key}: ${categories[key]}</li>`)
            })
        });
    }

    /**
     * Builds and returns an object that summarizes category points for the specified
     * set of questions
     *
     * @param {Array} questions:    questions to summarize
     * @return {Object} Object where a key is a category name, and the value is the total number of
     * points for that category (sum of max-scores for all questions of that category).
     *      Eg: {tech: 23, communication: 21}
     */
    function categoryPointSummaryForQuestions(questions) {
        const categories = {};
        $.each(questions, function(idx, ques) {
            const elem = $(ques);
            const categoryName = elem.data('category-name');
            const maxScore = elem.data('max-score');
            if (categories[categoryName]) {
                categories[categoryName] += maxScore;
            }
            else {
                categories[categoryName] = maxScore;
            }
        });
        return categories;
    }

    /**
     * Adds page to main sortable list
     *
     * @param {string} name:            page name
     * @param {boolean} userCreated:    whether or not this is a user created page
     * @param {Object} pageQuestions:   questions belonging to this page
     */
    function addPage(name, userCreated, pageQuestions) {
        const page = $(pageHtml(name));
        deleteElemHandler(page, '.page');
        page.find('.page-user-created-input').val(userCreated ? 1 : 0);
        page.find('.page-name-input').val(name);

        if (pageQuestions) {
            Object.values(pageQuestions).forEach(pq => {
                const ques = assessmentQuestions.find(i => i.id == pq.id);
                const question = $(questionHtml(ques));
                deleteElemHandler(question, '.question');
                page.find('.page-questions').append(question);
            });
        }

        initPageSortable(page);
        outerSortable.append(page);
    }

    /**
     * Adds sortable functionality to a (user created) page element. Allows questions to be sorted within a page. Also
     * allows questions to be moved out of page, into main list.
     *
     * @param {Object} page:    page to which we will add sortable functionality
     */
    function initPageSortable(page) {
        page.find('.sortable').sortable({
            connectWith: 'div.sortable',
            placeholder: 'placeholder',
            tolerance: 'intersect',
            axis: 'y',
            cursor: 'move',
            remove: () => updatePageCategoryPoints(),
            receive: () => updatePageCategoryPoints()
        })
    }

    /**
     * Page/question removal handler. When a delete icon (with class 'remove') is clicked,
     * we delete the appropriate parent element. For questions, the parent element has class 'question.'
     * For pages, the parent element has class 'page.'
     * 
     * @param {Object} elem:            page or question node
     * @param {string} parentClass:     parent class. specifies element to remove
     */
    function deleteElemHandler(elem, parentClass) {
        elem.find('.remove').on('click', function() {
            $(this).parents(parentClass).remove();
            updateQuestionSearchData();
            updateSummaryCategoryPoints();
            updatePageCategoryPoints();
        });
    }

    /**
     * Builds and returns an array of question names that are currently used on current form.
     *
     * @returns {Array} Array of questions name currently in use
     */
    function questionsToExclude() {
        const nameInputs = outerSortable.find('.question-name-input');
        const names = $.map(nameInputs, elem => elem.value);
        return names.filter(i => i.length);
    }
});