import IEFixes from './ie-fixes';
import * as d3 from 'd3';

/**
 * Creates a CSB BreakoutBarChart object that has a method "render" which
 * appends a bar chart to the provided d3 selection.
 *
 * The selected element should have an attribute 'data-scores' which is a JSON
 * object representing the user's category scores. If this attribute is missing
 * or empty, then no bar chart will be added.
 *
 * @param elementToSelect
 * A selection string or other d3-selectable object that identifies the single
 * element to which to append the Breakout Bar Chart.
 *
 * @constructor
 */
function BreakoutBarChart(elementToSelect) {
  // Ensure we can consistently talk about the correct "this" inside of class
  // methods
  let that = this;

  // Save the element
  this.elem = d3.select(elementToSelect);

  // Save the scores
  this.scores = JSON.parse(this.elem.attr('data-scores'));

  // The SVG where the chart is rendered.
  this.svg = null;

  // The div to use as a tooltip
  this.tooltip = null;

  const translationMap = $('#i18n').data();

    // A mapping from the internal ID of each skill category to its full name.
    const CATEGORIES = {
        'Critical Thinking': translationMap['criticalThinking'],
        'Communication': translationMap['communication'],
        'Customer Service': translationMap['customerService'],
        'Adaptability': translationMap['adaptability'],
        'Drive for Results': translationMap['driveForResults'],
        'Leads People': translationMap['leadsPeople'],
        'Technical': translationMap['technical']
    };


  /*****************************************************************************
   * PUBLIC METHODS                                                            *
   *****************************************************************************/

  /**
   * Render the bar chart.
   */
  this.render = function() {
    // If data-scores is undefined, there's nothing we can do
    if (!that.scores) {
      console.error("Scores is not defined for this element.");
      return;
    }

    // If svg is already defined, then we've already rendered this chart
    if (that.svg) return;

    // Map the scores (an object) into an array of strings
    const scoreData = Object.keys(that.scores).map( (k) =>
      ({ x: CATEGORIES[k],
        y: that.scores[k].score_percent
      })
    );

    // Geometry constants
    const margin = { top: 10, right: 20, bottom: 45, left: 40 },
      width = 300,
      height = 120,
      innerWidth = width - margin.left - margin.right,
      innerHeight = height - margin.top - margin.bottom;

    // Append the SVG that will contain the chart
    that.svg = that.elem.append('svg')
      .attr('class', 'rendered-breakout-bar-chart')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${width} ${height}`);

    IEFixes.fixSVGSize(that.svg, that.elem, width, height);

    // X transform
    const x = d3.scaleBand()
      .rangeRound([0, innerWidth]).padding(0.3)
      .domain(scoreData.map((d) => d.x));

    // Y transform
    const y = d3.scaleLinear()
      .rangeRound([innerHeight, 0])
      .domain([0.0, 1.0]);

    // The group that will contain most everything for the bar chart
    const g = that.svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Append the X axis
    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('transform', 'rotate(-15)');

    // Create the Y-axis generator
    const yAxis = d3.axisLeft(y)
      .ticks(5, '%')
      .tickSizeInner([-innerWidth]);

    // Append the Y axis
    g.append('g')
      .attr('class', 'axis axis--y')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text(translationMap['score']);

    // Create the tooltip
    createTooltip();

    // Finally, add the bars
    g.selectAll('.bar')
      .data(scoreData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x(d.x))
      .attr('y', (d) => y(d.y))
      .attr('width', x.bandwidth())
      .attr('height', (d) => innerHeight - y(d.y))
      .on('mousemove', showTooltip)
      .on('mouseout', hideTooltip);
  };


  /*****************************************************************************
   * PRIVATE METHODS                                                           *
   *****************************************************************************/

  /**
   * Creates the tooltip element, which is stored in the body of the page. If
   * a tooltip container (named #breakout-bar-tooltip) already exists, then
   * that will be selected and stored in that.tooltip instead.
   */
  let createTooltip = function() {
    // If the tooltip already exists in the body, grab it. Otherwise, create a
    // new one.
    that.tooltip = d3.select('#breakout-bar-chart-tooltip');
    if (!that.tooltip.size()) {
      that.tooltip = d3.select('body')
        .append('div')
        .attr('id', 'breakout-bar-chart-tooltip')
        .style('display', 'none');

      that.tooltip.append('div')
        .append('span')
        .attr('class', 'category-label');

      that.tooltip.append('div')
        .append('span')
        .attr('class', 'score-label');
    }
  };

  /**
   * A mouse handler function that displays the tooltip when the mouse hovers
   * over the bar. This should be called as a mouse even handler (so that
   * d3.event will be set).
   *
   * @param d
   * The data object associated with the bar that is being hovered over.
   */
  let showTooltip = function(d) {
    let tooltip = that.tooltip;

    // Set the category label to the bar's X value
    tooltip
      .select('span.category-label')
      .text(d.x);

    // Set the score label to the bar's Y value
    tooltip
      .select('span.score-label')
      .text(`${translationMap['score']}: ${(100.0 * d.y).toFixed(1)}%`);

    // Compute the bounding rect for this tooltip (this needs to be done after
    // setting the content).
    const boundingRect = tooltip.node().getBoundingClientRect();

    // Determine the height of the tooltip
    const height = boundingRect.height;

    // The middle of the tooltip should be at the mouse's Y-coordinate
    const top = d3.event.pageY - (height / 2.0);

    // The tooltip should be just left of the mouse.
    const left = d3.event.pageX + 25;

    tooltip
      .style('left', left + 'px')
      .style('top', top + 'px')
      .style('display', 'inherit');
  };

  /**
   * A mouse handler that hides the tooltip.
   */
  let hideTooltip = function() {
    that.tooltip.style('display', 'none');
  };

  return this;
}

export default BreakoutBarChart;
