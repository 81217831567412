import $ from 'jquery';

(() => {

  /**
   * Takes a name and a value and returns a new <option> tag with the text content of
   * the name argument and the value attribute of the value argument.
   * @param name {String} - The text content to be displayed on the option tag
   * @param value {String,Integer} - The value attribute for the option tag.
   * @returns {jQuery} - A new <option> tag with the appropriate name and value
   */
  var make_option = (name, value) => {
    return $('<option>').attr('value', value).text(name);
  };

  /**
   * Performs additional form validation. Currently checks validity of the phone
   * input on form submission. If the number is invalid, we scroll to the input
   * and append a helper message. If it is valid, we update the input's value
   * with the full international number and proceed with submission
   */
  const form_validation = () => {
    const $form = $('#employee_form');
    const $telephone = $('#telephone');
    const $document = $([document.documentElement, document.body]);

    $form.submit(function(event) {
      if ($telephone && !$telephone.intlTelInput("isValidNumber")) {
        $document.animate({scrollTop: $telephone.offset().top}, 800);
        $telephone.parent().append(`<span class="help-block">invalid number</span>`);
        event.preventDefault();
        return false;
      }
      else {
        // update value with full international number
        let fullNumber = $telephone.intlTelInput("getNumber");
        $telephone.val(fullNumber);
      }
      return true;
    })
  };

  /**
   *  Use this function on pages which have the DOB and phone fields. It will install
   *  all of the required behavior to do date checking and to force the phone number
   *  into the correct format.
   */
  var date_and_phone_behavior = () => {
    var $day_select = $('#dob_day');
    var $month_select = $('#dob_month');
    var $year_select = $('#dob_year');
    var $telephone = $('#telephone');
    /**
     * Takes a month and a year and computes what the last day of the month
     * should be.
     * @param month {String} - Name of the month in any case.
     * @returns {Integer} - The last day of the given month in the given year
     */
    var get_last_day_for_month = (month) => {
      //Most months have 31 days, the once that don't are cased out to 30
      // February has 28 days except in a leap year.
      switch (month) {
        case '4':
        case '6':
        case '9':
        case '11':
          return 30;
        case '2':
          return 29;
        default:
          return 31;
      }
    };

    /**
     * On each year change ensure the year is in acceptable ranges and update for leap year.
     */
    $year_select.change(() => {
      var value = $year_select.val();

      //If this is not a leap year remove February 29th
      if (value % 4 != 0 && $month_select.val() == 2) {
        if ($day_select.val() == 29) {
          $day_select.prop('selectedIndex', 0);
          $day_select.val('');
        }
        $.each($day_select.find('option'), function (index, day) {
          var $day = $(day);
          if ($day.val() > 28)
            $day.prop('disabled', 'disabled');
          else
            $day.prop('disabled', false);
        });
      } //If this is a leap year, add back feb 29 if missing
      else if (value % 4 == 0 && $month_select.val() == 2) {
        $.each($day_select.find('option'), function (index, day) {
          var $day = $(day);
          if ($day.val() > 29)
            $day.prop('disabled', 'disabled');
          else
            $day.prop('disabled', false);
        });
      }
      $day_select.formSelect();
    });

    /**
     * On each month change update the day to be in correct range and correct or leap year.
     */
    $month_select.change(() => {
      var last_day_of_month = get_last_day_for_month($month_select.val());
      if ($year_select.val() && $year_select.val() % 4 != 0 && $month_select.val() == 2)
        last_day_of_month = 28;


      if ($day_select.val() > last_day_of_month) {
        $day_select.prop('selectedIndex', 0);
        $day_select.val('');
      }

      $.each($day_select.find('option'), function (index, day) {
        var $day = $(day);
        if ($day.val() > last_day_of_month)
          $day.prop('disabled', 'disabled');
        else
          $day.prop('disabled', false);
      });

      $day_select.formSelect();
    });

    //Fill the year dropdown with years
    $year_select.attr('max', new Date().getFullYear());

    //Initialize intl-tel-input
    $telephone.intlTelInput({
      formatOnInit: true,
      formatOnDisplay: true,
      initialCountry: "US",
      utilsScript: require('intl-tel-input/build/js/utils')
    });

    //Check validity of phone number on input. If valid,
    //use setNumber so the input will auto-format, remove
    //helper message if exists, and set input to 'valid'
    $telephone.on('input', function() {
      if ($telephone.intlTelInput("isValidNumber")) {
        let number = $telephone.intlTelInput("getNumber");
        $telephone.intlTelInput("setNumber", number);
        $($telephone.siblings('.help-block')).remove();
        $telephone.addClass('valid');
        $telephone.removeClass('invalid');
      }
      else {
        $telephone.removeClass('valid');
        $telephone.addClass('invalid');
      }
    });
  };

  /**
   * This function installs the javascript page behavior for the /users/new route.
   */
  var users_new_behavior = () => {
    form_validation();
    date_and_phone_behavior();
    var $location_select = $('#location');
    var $employer_select = $('#employer');
    var $store_select = $('#store_number');
    var $position_select = $('#position');
    var $questions_container = $('#questions_container');

    $location_select.formSelect();
    $employer_select.formSelect();
    $store_select.formSelect();
    $position_select.formSelect();

    /**
     * This function is used to convert the server response into a dropdown
     * which can be used by the user to select positions.
     * @param positions - An array of positions.
     */
    var build_position_dropdown = (positions) => {
      $position_select.html(make_option('', ''));
      $.each(positions, (index, position) => {
        $position_select.append(make_option(position.name, position.id));
      });
      //This has to be called to make the dropdown work correctly per Materialize
      $position_select.formSelect();
    };
    /**
     * This function is used to convert the server response into a dropdown
     * which can be used by the user to select stores. If there is an
     * address it is appended to the store number '1234 - 8585 Kimo dr'
     * @param stores - An array of stores which may or may not have addresses
     */
    var build_store_dropdown = (stores) => {
      $store_select.html(make_option('', ''));
      $.each(stores, (index, store) => {
        var name = store.number;
        if (store.address)
          name += ' - ' + store.address;
        $store_select.append(make_option(name, store.id));
      });
    };

    /**
     * This function takes an employer object as returned from /employers/:id and
     * toggles the correct input fields to display based upon that employer's
     * settings and content. Also calls functions to build the dropdown contents.
     * @param employer - The object for the employer
     */
    var update_field_visibility_from_employer = (employer_info) => {
      // remove previous additional questions
      $questions_container.empty();

      //If there is no employer info, hide everything and return.
      if (!employer_info) {
        $('.employer_text_div').addClass('hide');
        $('.position_div').addClass('hide');
        $('.position_text_div').addClass('hide');
        $('.department_div').addClass('hide');
        $('.store_number_div').addClass('hide');
        $('.store_text_div').addClass('hide');
        return;
      }

      // If there is an employer text field show it, otherwise hide it
      if (employer_info.employer.has_employer_text)
        $('.employer_text_div').removeClass('hide');
      else
        $('.employer_text_div').addClass('hide');

      // If this employer has positions show the dropdown, otherwise hide it
      if (employer_info.employer.has_position) {
        if (employer_info.positions.length) {
          build_position_dropdown(employer_info.positions);
          $('.position_div').removeClass('hide');
          $('.position_text_div').addClass('hide');
        }
        else {
          $('.position_text_div').removeClass('hide');
          $('.position_div').addClass('hide');
        }
      }
      else {
        $('.position_div').addClass('hide');
        $('.position_text_div').addClass('hide');
      }

      //if this employer has departments, show the text field, otherwise hide it.
      if (employer_info.employer.has_department)
        $('.department_div').removeClass('hide');
      else
        $('.department_div').addClass('hide');

      // If this employer has store numbers determine if the input should
      // be text or dropdown field and hide the other, if there are no
      // store numbers, hide all the fields
      if (employer_info.employer.has_store_number) {
        if (employer_info.store_numbers.length) {
          build_store_dropdown(employer_info.store_numbers);
          $('.store_number_div').removeClass('hide');
          $('.store_text_div').addClass('hide');
          $store_select.attr('required','required');
          // for HTML5 "required" attribute
          $("select[required]").css({display: 'inline',
            position: 'absolute',
            float: 'left',
            padding: 0,
            margin: 0,
            border: '1px solid rgba(255,255,255,0)',
            height: 0,
            width: 0,
            top: '2em',
            left: '3em'});

          //This has to be called to make the dropdown work correctly per Materialize
          $store_select.formSelect();
        }
        else {
          $('.store_text_div').removeClass('hide');
          $('.store_number_div').addClass('hide');
          $store_select.removeAttr('required');
        }
      }
      else {
        $('.store_number_div').addClass('hide');
        $('.store_text_div').addClass('hide');
        $store_select.removeAttr('required');
      }

      // If this employer has additional questions
      if (employer_info.employer.has_additional_questions && employer_info.questions.length) {
        $questions_container.removeClass('hide');

        // For each question create an input and label.
        employer_info.questions.forEach(
            function (q) {
              var $p = $('<p>');

              // Label for current question
              var $label = $("<label>");
              $label.attr('for', q.id + '-question');
              $label.html(q.question);
              // Input field for question
              var $input = $("<input>");
              $input.attr("id", q.id + '-question');
              $input.attr("name", q.id + '-question');
              $input.attr("required", q.required);

              // Depending on question type the input will have a different type.
              // If question_type is boolean build 2 radio buttons
              if (q.question_type == "bool") {
                // Label for current question
                $label = $("<label>");
                $label.html(q.question);
                $p.append($label);

                $questions_container.append($p);
                $p = $('<p>');

                $input = $("<input>");
                $input.attr("id", 'yes-' + q.id + '-question');
                $input.attr("name", 'yes-' + q.id + '-question');
                $input.attr('type', "radio");
                $input.attr("style", "opacity:1;");
                $p.append($input);
                $label = $("<label>");
                $label.attr('for', 'yes-' + q.id + '-question');
                $label.html($('#i18n').data()['globalYes']);
                $p.append($label);

                $questions_container.append($p);
                $p = $('<p>');

                $input = $("<input>");
                $input.attr("id", 'no-' + q.id + '-question');
                $input.attr("name", 'no-' + q.id + '-question');
                $input.attr('type', "radio");
                $input.attr("style", "opacity:1;");
                $p.append($input);
                $label = $("<label>");
                $label.attr('for', 'no-' + q.id + '-question');
                $label.html($('#i18n').data()['globalNo']);
                $p.append($label);

                $questions_container.append($p);
                // uncheck radio button when the other is selected.
                $('#no-' + q.id + '-question')[0].onclick = function() {
                  $('#yes-' + q.id + '-question').prop("checked", false);
                };
                $('#yes-' + q.id + '-question')[0].onclick = function() {
                  $('#no-' + q.id + '-question').prop("checked", false);
                };
              } else if (q.question_type == "text") {
                $input.attr('type', "text");
                $input.attr("value", q.default_value);
                $p.append($label);
                $p.append($('<br>'));
                $p.append($input);

                $questions_container.append($p);
              }  else if (q.question_type == "dropdown") {
	              $input = $('<select />');
	              $input.attr("id", q.id + '-question');
	              $input.attr("name", q.multiple_values > 1 ? q.id + '-question[]' : q.id + '-question');
	              $input.attr("required", q.required);
	              if(q.multiple_values > 1) {
		              $input.attr("multiple", 'multiple');
		              $('<option disabled selected value> -- select an option -- </option>').appendTo($input);
	              }
	              var data = q.default_value.split("|");
	              for(var i in data) {
		              $('<option />', {value: data[i], text: data[i]}).appendTo($input);
	              }
	              $p.append($label);
	              $p.append($('<br>'));
	              $p.append($input);

	              $questions_container.append($p);
	              $input.formSelect();

	              if(q.multiple_values > 1) {
		              $('#' + q.id + '-question').on('change', function () {
			              if ($(this).val().length > q.multiple_values) {
				              $(this).val($(this).data('value'));
				              $('select').formSelect();
			              } else {
				              $(this).data('value', $(this).val());
			              }
		              });
	              }
              } else if (q.question_type == "date") {
	              $input.attr('type', "text");
	              $p.append($label);
	              $p.append($('<br>'));
	              $p.append($input);

	              $questions_container.append($p);
	              $('#' + q.id + '-question').datepicker();
              }
            }
        );
      }
      else {
        // if this employer doesn't have additional questions -> hide the question container and empty it.
        $questions_container.addClass('hide');
        $questions_container.empty();
      }

    };

    /**
     * This segment of code powers the population of the employer dropdown. When a location is selected
     * the back-end is queried for a list of employers in that location. Those employers are installed
     * into the dropdown.
     */
    $location_select.change(() => {
      $.get('/employers?location=' + $location_select.val()).then((employers) => {
        //Clear the old options values and install a null value at the top.
        $employer_select.html(make_option('', ''));

        //For each employer make an option tag and install it into the employer dropdown
        $.each(employers, (index, employer) => {
          $employer_select.append(make_option(employer.display_name, employer.id));
        });

        //Disable the select if there are no employers, otherwise enable the select
        if (employers.length)
          $employer_select.prop('disabled', false);
        else
          $employer_select.prop('disabled', 'disabled');

        //This has to be called to make the dropdown work correctly per Materialize
        $employer_select.formSelect();

      }, (error) => {
        console.log(error);
      });
    });

    /**
     * When an employer is selected we reach out to the server to find out what
     * types of fields should be displayed for this employer. We then hide/show
     * the necessary fields and populate any relevant dropdowns with the
     * information retrieved from the server.
     */
    $employer_select.change(() => {
      //If no employer selected, clear the fields and don't hit the endpoint
      if (!$employer_select.val()) {
        update_field_visibility_from_employer();
        return;
      }
      $.get('/employers/' + $employer_select.val()).then((employer_info) => {
        update_field_visibility_from_employer(employer_info)

      }, (error) => {
        console.log(error);
      })
    });
	  /**
	   * When the employer is not displayed then grab the first employer's questions.
	   */
	  var employer_value = $('#employer_value');
	  if(!$employer_select.val() && employer_value.val()){
	    $.get('/employers/' + employer_value.val()).then((employer_info) => {
		    update_field_visibility_from_employer(employer_info)
	    }, (error) => {
		    console.log(error);
	    })
    }

    // TODO: This is a kludge to fix an issue on Tarrant. At some point, we
    // should refactor this to use a more general approach.
    if ( $('body').hasClass('tarrant') ) {
      // Get the employer info for the current domain
      $.get('/domain_employer').then((employer_info) => {
        // Then update the field visibility
        update_field_visibility_from_employer(employer_info)
      }, (error) => { console.error(error); });
    }

  };
  var users_show_behavior = () => {
    date_and_phone_behavior();
    form_validation();
  };

  /**
   * The function, upon page load, determines which page is being visited and executes only the
   * javascript related to the current page.
   */
  $(document).ready(() => {
    if ($('body.users.new').length)
      users_new_behavior();
    else if ($('body.users.show').length)
      users_show_behavior();
    else if ($('body.users.take_win_training').length)
      document.getElementById("ltiLaunchFormSubmitArea").style.display = "none";
      document.ltiLaunchForm.submit();
  });
})();



