import $ from 'jquery';

$(document).ready(function() {
    // For admin view employers page data table
    $('#view_domains').DataTable({
        order: [[ 0, 'asc' ]],
        pageLength: 25
    });

    if ($('#view_admins_page').length) {
        $('table[id="view_admins"]').DataTable({
            order: [[ 0, 'asc' ]],
            pageLength: 25
        })
    }
});
