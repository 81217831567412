import FormWidget from './form_widget';
import BreakoutBarChart from '../charts/breakout-bar-chart';
import CoreScoreDial from '../charts/core-score-dial';
import swal from 'sweetalert'
import $ from 'jquery';
import * as d3 from 'd3';

$(document).ready(function () {
  if ($('#take-assessment-container').length) {
    const $container = $('#take-assessment-container');
    if (!$container.length) return;

    const translationMap = $('#i18n').data();

    // extract form JSON data
    const formData = $container.data('form');

    const $formDiv = $container.find('.form-div');
    if ($formDiv.length && formData) {
      // show user confirmation modal on incomplete assessment submission
      const handleIncompleteSubmit = (confirm, unansweredQuestions) => {
        let questionsHTMLText = '<p>'+translationMap['unansweredQuestionsTitle']+'</p>';
        unansweredQuestions.forEach((q, index) => {
          if(index < 5) {
            let text = $("<div/>").html(q[0]).text();
            let question = $('<p>', {attr:{'data-page':q[1]}, class:'question-link', text:index+1+'. '+ text.substring(0,50) + '...'});
            questionsHTMLText += question[0].outerHTML;
          }
        });
        swal({
          html: true,
          title: translationMap['areYouSure'],
          text: translationMap['incompleteAssessmentMsg'] + questionsHTMLText,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: translationMap['globalYes'],
          cancelButtonText: translationMap['globalNo']
        }, (confirmed) => {
          if (confirmed) confirm();
        });
          $('.question-link').click(function() {
            $formDiv.trigger('showPage', [$(this).data('page')]);
          });
      };

      // instantiate new FormWidget with the form data and the form container
      const formWidget = new FormWidget(formData, $formDiv, null, handleIncompleteSubmit);

      // render the form
      formWidget.render();
    }
  }
  if ($('body.assessments.show').length) {

    // Make the accordions collapsible
    $('.collapsible').collapsible();

    // Add the breakout bar charts
    d3.selectAll('.csb-breakout-bar-chart')
      .each(function () {
        new BreakoutBarChart(this).render()
      });

    // Enable modals
    $('.modal').modal({
      dismissible: true,
      opacity: .5
    });

    // Handle LinkedIn Learning link clicks. Create User Training record for this user
    $('.linkedin_training_table').find('a').click(function () {
      const link = $(this);
      const training = link.text();

      // if user clicked on a 'Pathway' link, just redirect
      if (training.toLowerCase() === 'pathway') return true;

      // create a User Training record for this user/training pair
      return $.ajax({
        url: '/user_trainings',
        type: 'POST',
        data: {training},
        success: () => true,
        error: error => {
          console.error(error);
          return true;
        }
      });
    });

    // Create and render the Core Score Dials.
    new CoreScoreDial('.core-score-dial', $('.core-score-dial').data()['level']).render();
  }
});
