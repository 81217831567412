/*
  Helper functions to construct Users/Employees tables for the view_all_domain_employees, view_single_employer, and view_single_platform views.
 */


let EmployeeTableHelper = {};

EmployeeTableHelper.deleteUserButton = (id, row) => {
  $.get("/delete_user_modal/"+ id, {}, function(html_string){
    let modal_element = $.parseHTML(html_string);
    $('body').append(modal_element);
    let deleteModal = $("#delete_user_modal-"+id);
    deleteModal.modal({
      dismissible: true,
      opacity: .5
    });
    deleteModal.trigger('init');
    deleteModal.modal('open');

    $('.delete-user').click(function () {
      let data = $(this).data();
      let input = $('#delete-user-name-input-' + data.userId);
      $(input[0]).parent('form').submit(function (e) {
        e.preventDefault();
      });
      if (input.val() === data.name) {
        input[0].setCustomValidity("");
        deleteModal.modal('close');
        deleteModal.remove();
        $.ajax({
          type: "DELETE",
          url: '/user/' + data.userId,
          success: function () {
            setTimeout(function () {
              $(row).remove();
            }, 500);
          }
        });
      } else {
        input[0].setCustomValidity("Incorrect Name Entered.");
      }
    });
  }, "html");
};

EmployeeTableHelper.employeeActionButtons = (data, type, employee, is_super_admin) => {
    let actions = '';
    if(employee.completed_assessment){
      actions = actions + `<a href="/dashboard/${employee.id}" title="View User Score"><i class="material-icons">visibility</i></a>`;
    }
    if(is_super_admin){
      actions = actions + `<a href="/update_user_form/${employee.id}" title="Edit User"><i class="material-icons">mode_edit</i></a>`;
      actions = actions + `<a class="btn-delete-modal" title="Delete User"><i class="material-icons">clear</i></a>`;
    }
    return actions;
};

EmployeeTableHelper.employeeBadges = (data, type, employee) => {
  let badges = ``;
  if(data.includes('Service Sector Entry Level')){
    badges += `<i class="fa fa-briefcase entry-color"></i>`;
  }else if(data.includes('Service Sector Advanced Level')){
    badges += `<i class="fa fa-briefcase advanced-color"></i>`;
  }else if(data.includes('Tech/IT Entry Level')){
    badges += `<i class="fa fa-microchip tech-color"></i>`;
  }else if(data.includes('HealthCare Entry Level')){
    badges += `<i class="fa fa-medkit healthcare-color"></i>`;
  }else if(data.includes('Manufacturing Entry Level')){
    badges += `<i class="fa fa-industry entry-color"></i>`;
  }
  return badges;
};

EmployeeTableHelper.employeeOverallRank = (data, type, employee) => {
  let translationMap = $('#i18n').data();
  let icon_classes = {
    good: 'fa-check-circle',
    top: 'fa-star',
    eligible: 'fa-desktop',
    incomplete: 'fa-asterisk'
  };
  let labels = {
    good: translationMap['good'],
    top: translationMap['topPerformer'],
    eligible: translationMap['skillUp'],
    incomplete: translationMap['incomplete']
  };
  let rank = icon_classes[data];
  return `<i class="fa ${rank} ${data} entry-color"></i> ` + labels[data];
};

EmployeeTableHelper.constructUsersTable = (table, url, is_super_admin) => {
  $(table).DataTable({
    pageLength: 25,
    serverSide: true,
    ajax: {
      url: url
    },
    dom: 'Blftrip',
    buttons: [],
    columns: [
      {data: 'first_name', orderable: true},
      {data: 'last_name', orderable: true},
      {data: 'contact_email', orderable: true},
      {data: 'core_score', orderable: false},
      {
        data: 'overall_rank',
        orderable: false,
        render: EmployeeTableHelper.employeeOverallRank
      },
      {
        data: 'badges',
        orderable: false,
        render: EmployeeTableHelper.employeeBadges
      },
      {
        data: 'action',
        orderable: false,
        render: function (data, type, employee) {
          return EmployeeTableHelper.employeeActionButtons(data, type, employee, is_super_admin);
        }
      }
    ],
    order: [0, 'asc'],
    createdRow: function (row, data, dataIndex) {
      const {
        id
      } = data;

      $(row).attr('id', '#user-row-'+ id);

      $(row).find('.btn-delete-modal').click(function () {
        EmployeeTableHelper.deleteUserButton(id, row);
      });
    }
  });
};

EmployeeTableHelper.constructLimitedUsersTable = (table, url, is_super_admin) => {
  $(table).DataTable({
    pageLength: 25,
    serverSide: true,
    ajax: {
      url: url
    },
    dom: 'Blftrip',
    buttons: [],
    columns: [
      {data: 'first_name', orderable: true},
      {data: 'last_name', orderable: true},
      {data: 'employer', orderable: false},
      {data: 'contact_email', orderable: true},
      {
        data: 'action',
        orderable: false,
        render: function (data, type, employee) {
          return EmployeeTableHelper.employeeActionButtons(data, type, employee, is_super_admin);
        }
      }
    ],
    order: [0, 'asc'],
    createdRow: function (row, data, dataIndex) {
      const {
        id
      } = data;

      $(row).attr('id', '#user-row-'+ id);

      $(row).find('.btn-delete-modal').click(function () {
        EmployeeTableHelper.deleteUserButton(id, row);
      });


    }
  });
};

module.exports = EmployeeTableHelper;