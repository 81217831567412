import swal from 'sweetalert'
import $ from 'jquery';

$(document).ready(function() {
    function initDownloadLinks() {
        const $dlLink = $('a.export-download');
        $dlLink.unbind('click', downloadLinkClick);
        $dlLink.bind('click', downloadLinkClick);
    }

    function downloadLinkClick(e) {
        const id = $(this).data('id');

        $.ajax({
            url: `/data_exports/url/${id}`,
            type: 'GET',
            success: data => {
                const { url } = data;
                if (url && url.length) window.location.href = url;
            },
            error: () => {
                swal({
                    title: 'Oops',
                    text: 'An error occurred. Unable to download data export.',
                    type: 'warning'
                });
            }
        });

        e.preventDefault();
        return false;
    }

    // data export index
    if ($('.data_exports.index').length) {
        const $dataExportTable = $('#data_export_table');
        $dataExportTable.DataTable({
            order: [[ 5, 'asc' ]],
            pageLength: 25,
            fnDrawCallback: () => {
                initDownloadLinks();
            }
        });

        $('.tooltipped').tooltip({
            position: { my: 'right' }
        });

        initDownloadLinks();
    }

    // data export new
    if ($('.data_exports.new').length || $('.data_exports.create').length) {
        const $form = $('#new_data_export');

        // remove all hidden fields on form submission
        $form.submit(function() {
            $.each($form.find('div:hidden'), function() {
                $(this).remove();
            });
        });

        const $data = $('#data');
        const employers = $data.data('employers');
        const exportTypes = $data.data('exportTypes');
        const domainForms = $data.data('domainForms');
        const domainAssessments = $data.data('domainAssessments');
        const allAssessments = $data.data('allAssessments');

        const $domainSelect = $('select#data_export_domain_id');
        const $domainSelectWrapper = $domainSelect.closest('div:not(.select-wrapper)');
        $domainSelectWrapper.hide();
        const $filterSelect = $('select#filter');
        const $platformSelect = $('select#data_export_platform_id');
        const $platformSelectWrapper = $platformSelect.closest('div:not(.select-wrapper)');
        $platformSelectWrapper.hide();
        const $formSelect = $('select#data_export_form_id');
        const $formSelectWrapper = $formSelect.closest('div:not(.select-wrapper)');
        $formSelectWrapper.hide();
        const $assessmentSelect = $('select#data_export_assessment_id');
        const $assessmentSelectWrapper = $($assessmentSelect[0].parentNode);
        $assessmentSelectWrapper.hide();
        const $employerSelect = $('select#data_export_employer_id');
        const $employerSelectWrapper = $employerSelect.closest('div:not(.select-wrapper)');
        $employerSelectWrapper.hide();

        const $exportTypeSelect = $('select#data_export_export_type_id');

        $exportTypeSelect.change(function(e) {
            const selected = $(this).children(":selected"),
                typeId = parseInt(selected.val());

            const type = exportTypes.find(x => x.id === typeId);

            if (!type) return;

            if (type.name === 'Assessment') {
                $formSelectWrapper.show();
                $assessmentSelectWrapper.hide();
            }
            else if (type.name === 'Employee') {
                $formSelectWrapper.hide();
                $assessmentSelectWrapper.show();
            }
            else if (type.name === 'Assessment Summary') {
                $formSelectWrapper.hide();
                $assessmentSelectWrapper.hide();
            }
        });

        $filterSelect.change(function() {
            const selected = $(this).children(":selected"),
                selectedVal = selected.val();

            if (selectedVal === 'domain') {
                $domainSelectWrapper.show();
                $platformSelectWrapper.hide();
            }
            else if (selectedVal === 'platform') {
                $domainSelectWrapper.hide();
                $platformSelectWrapper.show();
                $employerSelectWrapper.hide();
            }
            else if (selectedVal === 'all') {
                $domainSelectWrapper.hide();
                $platformSelectWrapper.hide();
                $employerSelectWrapper.hide();
                const forms = [].concat( ...Object.values(domainForms) );
                updateFormSelect($formSelect, forms);
                updateAssessmentSelect($assessmentSelect, allAssessments)
            }
        });

        $domainSelect.change(function() {
            const selected = $(this).children(":selected"),
                selectedVal = parseInt(selected.val());

            if (domainForms.hasOwnProperty(selectedVal)) {
                const forms = domainForms[selectedVal];
                updateFormSelect($formSelect, forms);
            }

            if (domainAssessments.hasOwnProperty(selectedVal)) {
                const assessments = domainAssessments[selectedVal];
                const assessments_array = [];
                assessments.forEach(ass => {
                  assessments_array.push(ass.assessment);
                });
                updateAssessmentSelect($assessmentSelect, assessments_array);
            }

            const domainEmployers = employers.filter(x => x.domain_id === selectedVal);
            updateEmployerSelect($employerSelect, domainEmployers);
            $employerSelectWrapper.show();
        });
    }

    function updateFormSelect($formSelect, forms) {
        $formSelect.empty();
        forms.forEach(x => {
            $formSelect.append($('<option>', {text: x.name, value: x.id}));
        });
        $formSelect.prepend(`<option value=""></option>`);
        $formSelect.val('');
        $formSelect.formSelect();
    }

    function updateAssessmentSelect($assessmentSelect, assessments) {
        $assessmentSelect.empty();
        assessments.forEach(assessment => {
          const { name, level, id } = assessment;
          $assessmentSelect.append($('<option>', {text: `${name} ${level}`, value: id}))
        });
        $assessmentSelect.prepend(`<option value=""></option>`);
        $assessmentSelect.val('');
        $assessmentSelect.formSelect();
    }

    function updateEmployerSelect($employerSelect, employers) {
        $employerSelect.empty();
        employers.forEach(x => {
            $employerSelect.append($('<option>', {text: x.display_name, value: x.id}))
        });
        $employerSelect.prepend(`<option value=""></option>`);
        $employerSelect.val('');
        $employerSelect.formSelect();
    }
});
