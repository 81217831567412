import $ from 'jquery';

$(document).ready(function() {
    const $formDiv = $('.assessment-question-category-form');
    if (!$formDiv.length) return;

    const $form = $formDiv.find('form');

    $form.on('cocoon:after-insert', function(e, item) {
        item.find('select').formSelect();
    });
});
