import constants from '../shared/constants';

$(document).ready(function() {
    const $formDiv = $('.assessment-question-form');
    if (!$formDiv.length) return;

    const questionTypes = $formDiv.data('questionTypes');
    const $newQuestionForm = $formDiv.find('form.new_assessment_question');
    let categoryModal = null;
    const categorySelect = $('select#assessment_question_assessment_question_category_id');
    const $typeSelect = $('select#assessment_question_assessment_question_type_id');
    const $answersSection = $('span.answers-section');

    $newQuestionForm.submit(function(e) {
        const questionType = getSelectedQuestionType(),
            { name: questionTypeName } = questionType;

        // remove answer options unless question type is Single or Multiple Select
        if (![ constants.SINGLE_SELECT, constants.MULTIPLE_SELECT ].includes(questionTypeName)) {
            $('.row.question-answers').empty();
        }
    });

    appendAddCategoryOption();

    categorySelect.change(function() {
       const selected = $(this).children(":selected");
       if (selected.hasClass('add-category-button')) {
           try {
               $('#assessment_question_category_modal').modal('open');
           }
           catch (e) {
               categoryModal = $('#assessment_question_category_modal');
               categoryModal.on('init', onModalInit);
               categoryModal.trigger('init');
               categoryModal.modal('open');
           }

           categorySelect.val("");
           categorySelect.formSelect();
       }
    });

    // custom event used to refresh the category select dropdown list. should be invoked
    // anytime a new category is created
    categorySelect.on('refresh', function(e, newCategoryName = null) {
        $.ajax({
            url: '/assessment_question_categories',
            type: 'GET',
            success: (resp) => {
                const { categories } = resp;
                categorySelect.empty();
                categories.forEach(cat => {
                    categorySelect.append(`<option value="${cat.id}">${cat.name}</option>`)
                });

                // set selected category to newly created category if specified
                if (newCategoryName) {
                    const newCategory = categories.find(x => x.name === newCategoryName);
                    if (newCategory) {
                        categorySelect.val(newCategory.id.toString());
                    }
                }

                appendAddCategoryOption();
            },
            error: error => console.error(error)
        });
    });

    // opens the add category form modal
    const $addCategoryBtn = $('.add-category-button');
    if ($addCategoryBtn.length) {
        $addCategoryBtn.on('select', function() {
            // try to open modal. if we enter the catch block, we have probably re-rendered the modal
            // and it needs to be re-initialized
            try {
                $('#assessment_question_category_modal').modal('open');
            }
            catch (e) {
                categoryModal = $('#assessment_question_category_modal');
                categoryModal.on('init', onModalInit);
                categoryModal.trigger('init');
                categoryModal.modal('open');
            }
        });
    }

    // handles answer template select. clicks a hidden link, which should add the
    // template's answers to the form
    const $templateSelect = $('.template-select');
    $templateSelect.on('change', function() {
        const selected = $(this).val();
        if (selected === 'likert') {
            $formDiv.find(`.${selected}-template-link`).click();
        }
    });

    $typeSelect.change(function() {
        const selectedType = getSelectedQuestionType();
        if (!selectedType) return;

        const { name } = selectedType;

        $answersSection.toggle([ constants.SINGLE_SELECT, constants.MULTIPLE_SELECT ].includes(name));
    });

    /**
     * Initializes add category form modal. Triggered by the 'init' event on the
     * 'assessment_question_category_modal'
     *
     * @returns {boolean} Returns false to stop 'init' event propagation
     */
    function onModalInit() {
        const modal = $(this);
        modal.modal({
            dismissible: true,
            opacity: .5
        });

        const categoryForm = modal.find('#create-category-form');
        if (!categoryForm.length) return false;

        // handle category form errors (usually model validation failure)
        categoryForm.on('ajax:error', function(event, xhr) {
            const { responseJSON } = xhr;
            if (responseJSON && responseJSON.length) {
                const errorContainer = categoryModal.find('.form-errors');
                if (!errorContainer.length) return;
                errorContainer.css('display', 'block');
                responseJSON.forEach(item => {
                    errorContainer.find('ul').append(`<li>${item}</li>`);
                })
            }
        });

        // handle category form success
        categoryForm.on('ajax:success', function(event, data) {
            // get the newly created category's name
            const newCategory = modal.find('input#assessment_question_category_name').val();
            // close and remove the spent modal
            modal.modal('close');
            modal.remove();
            // category modal (form) html is returned on success. re-add it to DOM
            // so additional categories can be created
            $formDiv.after(data);
            // trigger category select refresh so new category is included
            categorySelect.trigger('refresh', newCategory);
        });

        // initialize select on category rank add
        modal.on('cocoon:after-insert', function(e, item) {
            item.find('select').formSelect();
        });

        return false;
    }

    /**
     * Appends an "Add New Category" option to the category select dropdown
     */
    function appendAddCategoryOption() {
        const opt = $(`<option class="add-category-button">Add a New Category</option>`);
        opt.prepend('<i class="material-icons">add</i>');
        categorySelect.append(opt);
        categorySelect.formSelect();
    }

    function getSelectedQuestionType() {
        const selected = $typeSelect.children(":selected"),
            typeId = parseInt(selected.val());
        return questionTypes.find(x => x.id === typeId);
    }
});
