$(document).ready(function() {
  //Make Materialize dropdowns work
  $('select').formSelect();
  $.ajaxSetup({headers:
      { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
  });
});
/**
 * Provide a mechanism for reporting outbound links to Google Analytics
 * @param url - The outbound URL being visited
 */
var trackOutboundLink = function(url) {
    ga('send', 'event', 'outbound', 'click', url, {
        'transport': 'beacon',
        'hitCallback': function(){document.location = url;}
    });
};