import $ from 'jquery';

$(document).ready(function(){
  const $select = $('#locale-select');
  const $input = $('.locale-selector .select-wrapper input');
  const translationMap = $('#i18n').data();
  let currentLocale = null;

  // get current locale on load and update select value
  getLocale(locale => {
      currentLocale = locale;
      updateSelect();
  });

  // listen for changes on locale selector. on change,
  // make post request with current selection
  $select.on('change', e => {
    const newLocale = $select.val();
    if (newLocale === currentLocale) return;

    // AJAX call for employer csv data
    $.ajax({
      url: '/locale',
      type: 'POST',
      data: {locale: newLocale},
      success: () => location.reload(),
      error: error => console.error(error)
    });
  });

  // updates select according to current locale
  function updateSelect() {
    const str = currentLocale === 'en' ? translationMap['localeEn'] : translationMap['localeEs'];
    $input.val(str);
  }

  // retrieves user's current locale
  function getLocale(success) {
      $.ajax({
          url: '/locale',
          type: 'GET',
          success: resp => {
              const { locale } = resp;
              if (success) success(locale);
          },
          error: error => console.error(error)
      });
  }
});



