$(document).ready(function() {
    const $tabs = $('#assessment-admin-tabs');

    if (!$tabs.length) return;

    const controller = $tabs.data('controller');

    if (controller === 'forms') {
        $tabs.find('.forms-tab').addClass('active');
        $tabs.find('.questions-tab').removeClass('active');
    }
    else if (controller === 'assessment_questions') {
        $tabs.find('.questions-tab').addClass('active');
        $tabs.find('.forms-tab').removeClass('active');
    }
});
