import Sanitize from '../shared/sanitize';
import constants from '../shared/constants';
import swal from 'sweetalert'
import $ from 'jquery';

/**
 * Assessment form widget. Allows a user to fill-out and submit an assessment form.
 */
class FormWidget {
  /**
   * FormWidget constructor
   *
   * @param {Object} data:                form data
   * @param {string} data.session_id:     session ID for this attempt
   * @param {number} data.id:             form ID
   * @param {Array<Object>} data.pages:   pages belonging to the form
   * @param container:                    node in which we will render the assessment
   * @param {Function?} afterSubmit:      optional callback to invoke after assessment submission
   * @param {Function} incompleteSubmit:  function invoked when user submits an incomplete assessment.
   *                                      allows us to show a confirmation modal
   */
  constructor(data, container, afterSubmit, incompleteSubmit) {
    this.data = data;
    this.formWidgetContainer = $('<div class="form-widget"></div>');
    let that = this;
    $('.form-div').on('showPage', function (e, page) {
      that.showPage(page);
      swal.close();
    });
    $(container).append(this.formWidgetContainer);
    this.currentPage = null;
    this.afterSubmit = afterSubmit;
    this.incompleteSubmit = incompleteSubmit;
    this.relaxedSanitizer = new Sanitize(Sanitize.Config.RELAXED);
    this.restrictedSanitizer = new Sanitize(Sanitize.Config.RESTRICTED);
    this.translationMap = $('#i18n').data();

    const {id: form_id, session_id} = this.data;

    // object used as payload in form submission request
    this.form = {
      form_id,
      session_id,
      responses: []
    };
  }

  /**
   * Render the form
   */
  render() {
    // make sure we have form data and a container
    if (!this.data || !this.formWidgetContainer) {
      console.error('Missing required FormWidget data');
      return;
    }

    const {error, message = 'An error has occurred'} = this.data;

    // if an error occurred while serializing form data, show message and bail
    if (error) {
      this.formWidgetContainer.append(`<h5>${message}</h5>`);
      return;
    }

    this.initFormContainers();
    this.initPagination();
    this.initSubmit();
    const {pages} = this.data;
    if (pages && pages.length) {
      this.showPage(1);
    }
  }

  /**
   * Adds base-level DOM elements to the parent FormWidget container
   */
  initFormContainers() {
    this.pageContainer = $('<div class="page-container"></div>');
    this.paginationContainer = $('<div class="pagination-container"></div>');
    this.formWidgetContainer.append([this.pageContainer, this.paginationContainer]);
  }

  /**
   * Shows the specified page
   *
   * @param {number} page:  index of page to show
   */
  showPage(page) {
    this.currentPage = page;
    // remove current page elements
    this.pageContainer.empty();
    // find data for the specified page
    const pageData = this.data.pages.find(i => i.order === page);
    // render the specified page
    if (pageData) {
      this.pageContainer.append(this.buildPageQuestions(pageData));
      // update pagination/answer highlighting
      this.updateProgress(page);
      this.highlightAnswers();
      $('html, body').animate({scrollTop: this.pageContainer.offset().top});
    }

    // replace Next button with Submit if on the last page
    this.updateSubmit(page);

    // hide the Previous button if on the first page
    const prevBtnVisibility = page === 1 ? 'hidden' : 'visible';
    this.paginationContainer.find('.previous').css('visibility', prevBtnVisibility);
  }

  /**
   * Hides/shows the Submit button depending on current page
   *
   * @param {number} page:    current page
   */
  updateSubmit(page) {
    const {pages} = this.data;
    // replace the Next button with Submit button if user if on the
    // last page
    if (page === pages.length) {
      this.submitBtn.show();
      this.paginationContainer.find('.next').hide();
    }
    else {
      this.submitBtn.hide();
      this.paginationContainer.find('.next').show();
    }
  }

  /**
   * Initializes form submit handler.
   */
  initSubmit() {
    const that = this;
    this.submitBtn = $(`<button class="submit-btn" id="submit-form-btn">${that.localizedText('submit')}</button>`);
    this.submitBtn.hide();
    // make ajax request to /forms/completed/:session_id on form submit
    this.submitBtn.on('click', function () {
      let unansweredQuestionIds = that.questionUnanswered();
      if (!unansweredQuestionIds.length) return that.submitAssessment();

      if (that.incompleteSubmit) that.incompleteSubmit(() => that.submitAssessment(), unansweredQuestionIds);
    });
    this.paginationContainer.append(this.submitBtn);
  }

  /**
   * Submits the user's responses via AJAX and redirects the user on completion
   */
  submitAssessment() {
    // Disable the submit button to prevent multiple submissions (this is also
    // addressed on the backend, but here for a more pleasant user experience)
    this.submitBtn.attr('disabled', 'disabled');

    $.ajax({
      url: `/forms/completed/${this.form.session_id}`,
      type: 'POST',
      data: this.form,
      success: (resp) => {
        // redirect user to the returned URL
        const {error, message = 'An error occurred.', location} = resp;
        if (location && location.length) {
          if (this.afterSubmit) this.afterSubmit(location);
          else window.location.href = location;
        }
        if (error) {
          swal({title: 'Error', text: message, type: "warning"});
        }
      },
      error: error => console.error(error)
    });
  }

  /**
   * Initializes form pagination
   */
  initPagination() {
    const that = this;

    const {pages} = this.data;
    if (!pages || !pages.length) return;

    this.paginationContainer.append(`
<div class="form-progress">
    <div class="bar-container">
        <div class="bar">
        </div>
    </div>
    <div class="page-indicator">
        ${that.localizedText('page')} 
        <select class="current-page browser-default"></select> 
        ${that.localizedText('of')} ${pages.length}
    </div>
</div>`);

    const pageSelect = this.paginationContainer.find('select.current-page');

    for (var i = 0; i < pages.length; i++) {
      pageSelect.append($(`<option value="${i + 1}">${i + 1}</option>`));
    }

    pageSelect.on('change', function () {
      that.showPage(parseInt(this.value));
    });

    // prepend a previous page item
    const previous = $(`<a href="#!" class="pagination-btn previous">${that.localizedText('previous')}</a>`);
    previous.on('click', () => that.previousPage());
    this.paginationContainer.prepend(previous);

    // append a next page item
    const next = $(`<a href="#!" class="pagination-btn next">${that.localizedText('next')}</a>`);
    next.on('click', () => that.nextPage());
    this.paginationContainer.append(next);
  }

  /**
   * Returns localized text for the given key
   *
   * @param {string} key:     key used in translationMap
   * @returns {*} Returns the localized string if it exists
   */
  localizedText(key) {
    if (this.translationMap && this.translationMap[key]) return this.translationMap[key];

    switch (key) {
      case 'previous':
        return 'Previous';
      case 'next':
        return 'Next';
      case 'page':
        return 'Page';
      case 'of':
        return 'of';
      case 'submit':
        return 'Submit';
      default:
        return key;
    }
  }

  /**
   * Updates assessment progress bar and page indicator
   *
   * @param {number} currentPage:     index of the current page
   */
  updateProgress(currentPage) {
    const {pages} = this.data;
    const progress = Math.ceil(((currentPage - 1) / pages.length) * 100);

    this.paginationContainer.find('.bar').css('width', `${progress}%`);
    this.paginationContainer.find('.current-page').val(currentPage);
  }

  /**
   * Builds and returns an array of DOM elements for all questions on the given page.
   *
   * @param {Object} pageData:        data for given page
   * @returns {Array<Object>} Array of DOM objects representing the questions on this page
   */
  buildPageQuestions(pageData) {
    var translationMap = $('#i18n').data();
    const that = this;
    const {assessment_questions, id: pageId} = pageData;
    return assessment_questions.map(question => {
      const {assessment_question_answers, id: questionId, prompt, display_horizontal = false, assessment_question_type} = question,
          { name: questionType } = assessment_question_type;

      const elem = $(
        `<div class="question">
            <div class="prompt"></div>
            <div class="answers"></div>
         </div>`);

      if (display_horizontal) elem.find('.answers').addClass('horizontal');

      const wrappedPrompt = $('<span></span>').append(prompt);
      if (questionType === constants.MULTIPLE_SELECT)
        wrappedPrompt.append(translationMap['selectAnswers']);

      elem.find('.prompt').append(that.relaxedSanitizer.clean_node(wrappedPrompt[0]));

      elem.find('.answers').append(this.buildQuestionAnswers(assessment_question_answers, questionId, pageId, questionType));
      return elem;
    })
  }

  /**
   * Builds and returns array of answer DOM elements for the given question.
   *
   * @param {Array<Object>} answers:      the question's answer options
   * @param {number} questionId:          question's ID
   * @param {number} pageId:              ID of page the question belongs to
   * @param {string} questionType:          the type of question
   * @returns {Array<Object>} Array of DOM objects representing the answer options
   * for this question
   */
  buildQuestionAnswers(answers, questionId, pageId, questionType) {
    const that = this;

    if (questionType === constants.FREETEXT) {
      const match = that.form.responses.find(i => i.questionId === questionId);
      const elem = $(`<textarea class="answer form-control" />`).attr({
        'data-question-id': questionId,
        'data-question-type': questionType,
        'data-page-id': pageId
      }).text(match ? match.answerText : '');
      elem.bind('input', function () {
        that.answerQuestion(this);
      });

      return elem;
    }

    return answers.map(a => {
      const elem = $(`<div class="answer"></div>`).attr({
        'data-answer-id': a.id,
        'data-question-id': questionId,
        'data-question-type': questionType,
        'data-page-id': pageId
      });

      const wrappedAnswer = $('<span></span>').append(a.text);
      elem.append(that.restrictedSanitizer.clean_node(wrappedAnswer[0]));
      // add click listener to each answer
      elem.on('click', function () {
        that.answerQuestion(this);
      });
      return elem
    })
  }

  /**
   * Invoked when user selects an answer.
   *
   * @param {Object} answer:      selected DOM element
   */
  answerQuestion(answer) {
    const elem = $(answer);
    const answerId = elem.data('answer-id');
    const questionType = elem.data('question-type');
    const questionId = elem.data('question-id');
    const pageId = elem.data('page-id');


    if (questionType === constants.FREETEXT) {
      // determine whether or not another answer for this question has already been selected.
      // if so, remove the old answer from responses array (deselect it)
      let questionMatch = -1;
      this.form.responses.forEach(function(i, index) {
        if (i.questionId === questionId) {
          questionMatch = index
        }
      });
      if (questionMatch >= 0) {
        this.form.responses.splice(questionMatch, 1);
      }
      // add the selected answer to responses array, update answer highlighting,
      // and check form completion status
      let answerText = elem.val();
      if (answerText.length) this.form.responses.push({answerText, questionId, pageId});
    }
    else {
      // determine whether or not this exact answer has already been selected. if so,
      // remove it from responses array (deselect it)
      let exactMatch = -1;
      this.form.responses.forEach(function(i, index) {
        if (i.questionId === questionId && i.answerId === answerId) {
          exactMatch = index
        }
      });
      if (exactMatch >= 0) {
        this.form.responses.splice(exactMatch, 1);
        this.highlightAnswers();
        return;
      }

      if (questionType === constants.SINGLE_SELECT) {
        // determine whether or not another answer for this question has already been selected.
        // if so, remove the old answer from responses array (deselect it)
        let questionMatch = -1;
        this.form.responses.forEach(function(i, index) {
          if (i.questionId === questionId) {
            questionMatch = index
          }
        });
        if (questionMatch >= 0) {
          this.form.responses.splice(questionMatch, 1);
        }
      }

      // add the selected answer to responses array, update answer highlighting,
      // and check form completion status
      this.form.responses.push({answerId, questionId, pageId});
      this.highlightAnswers();
    }
  }

  /**
   * Highlights selected answers. Should be invoked anytime an answer is selected/deselected
   */
  highlightAnswers() {
    const that = this;
    $.each(this.formWidgetContainer.find('.answer'), function (idx, val) {
      const answer = $(val);
      const questionId = answer.data('question-id');
      const answerId = answer.data('answer-id');
      // look for this answer in responses array
      const match = that.form.responses.find(i => i.answerId === answerId && i.questionId === questionId);
      // set class based on a match presence
      if (match) answer.addClass('active');
      else answer.removeClass('active');
    })
  }

  /**
   * Attempts to find an unanswered question
   *
   * @returns {Array} The unanswered questions if any are found
   */
  questionUnanswered() {
    const {pages} = this.data;
    // all answer ids in the form
    let index = 0;
    const answers = pages.slice().reverse().reduce((prev, curr) => {
      index +=1;
      const {assessment_questions} = curr;
      let pageIds = new Array(assessment_questions.length);
      pageIds.fill(index);
      return prev.concat(assessment_questions.map(function(e, i) {
        return [e, pageIds[i]];
      }))
    }, []);
    // find answer id that isn't present in responses array
    return answers
        .filter(x => x[0].assessment_question_type.name !== constants.INFORMATIONAL)
        .filter(answer => !this.form.responses.find(i => i.questionId === answer[0].id)).map(q => [q[0].prompt, q[1]]);
  }

  /**
   * Sends user to the next page if one exists
   */
  nextPage() {
    const {pages} = this.data;
    if (pages.length > this.currentPage) {
      this.showPage(this.currentPage + 1)
    }
  }

  /**
   * Sends user to the previous page if one exists
   */
  previousPage() {
    if (this.currentPage > 1) this.showPage(this.currentPage - 1);
  }
}

export default FormWidget;
