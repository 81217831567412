import $ from 'jquery';

(() => {

  /*
    Limits the number of selected values for the "Which position(s) are you interested in? (Limit 5)" multi-select
   */
  $(document).ready(function() {

    let last_valid_selection = null;

    $('#interested_positions').on('change', function() {
      if ($(this).val().length > 5) {
        $(this).val($(this).data('value'));
        $('select').formSelect();
      } else {
        $(this).data('value', $(this).val());
      }
    });

    if($('#hear_about_event').val() == "Other") {
      $('#hear_about_event_text_div').show();
    } else {
      $('#hear_about_event_text_div').hide();
    }

    $('#hear_about_event').change(() => {
      if($('#hear_about_event').val() == "Other"){
        $('#hear_about_event_text_div').show();
      }else {
        $('#hear_about_event_text_div').hide();
      }
    });

  });
})();



