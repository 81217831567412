/**
 * Defines a variety of methods for fixing display issues with Internet Explorer.
 *
 * @constructor
 */
function IEFixes() {
  /**
   * Determine if the current browser is a version of Internet Explorer.
   *
   * @return {boolean}
   */
  this.isIE = () => {
    return window.navigator.userAgent.indexOf('MSIE') > 0 ||
      (!!window.MSInputMethodContext && !!document.documentMode);
  };

  /**
   * Install a handler to resize an svg to occupy the full width of its
   * container in a way that works on Internet Explorer. Internet Explorer
   * requires a non-relative height to be set on an SVG for it to display
   * correctly.
   *
   * @param {d3.selection} svg
   * The SVG to resize
   *
   * @param {d3.selection} parent
   * The parent of the SVG.
   *
   * @param {int} vbWidth
   * The width of the SVG's virtual box.
   *
   * @param {int} vbHeight
   * The height of the SVG's virtual box.
   */
  this.fixSVGSize = (svg, parent, vbWidth, vbHeight) => {
    if (!this.isIE()) return;

    // A function to modify set the height of the SVG relative to the width
    // of its container.
    const resize = () => {
      // Determine the width of the container
      const elemWidth = parent.node().getBoundingClientRect().width;
      // Compute the desired SVG height from the width of the container
      const fixedHeight = elemWidth * vbHeight / vbWidth;
      // Set the height
      svg.attr('height', fixedHeight);
    };

    // Call it immediately
    resize();

    // Then install a handler to call it when the window resizes
    $(window).resize(resize);
  };
}

export default new IEFixes();