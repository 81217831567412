import * as d3 from 'd3';

// Require Dependencies
import 'materialize-css/dist/js/materialize.min.js';
import 'intl-tel-input/build/js/intlTelInput-jquery.min';
import "cocoon-js";
import 'file-saver';

require('babel-polyfill');
require('jquery-ui/ui/widgets/sortable');
require('jquery-mask-plugin');
require('datatables');
require('libphonenumber-js');


// Require JS files
require('./main');

// Admin JS files
require('./admin/admin_landing');
require('./admin/assessment_admin_tabs');
require('./admin/assessment_question_categories');
require('./admin/assessment_questions');
require('./admin/assessment_questions_index');
require('./admin/data_exports');
require('./admin/forms');
require('./admin/forms_index');
require('./admin/view_all_domain_employees');
require('./admin/view_employers');
require('./admin/view_single_employer');
require('./admin/employers');
require('./admin/questions');

// User JS files
require('./user/assessments');
require('./user/users');
require('./user/score_report');
require('./user/form_widget');
require('./user/dashboard');
require('./user/new');

// Shared JS file
require('./shared/sanitize');
require('./shared/constants');
require('./shared/navigation');

// Charts JS files
require('./charts/breakout-bar-chart');
require('./charts/core-score-dial');
require('./charts/ie-fixes');





