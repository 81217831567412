import $ from 'jquery';

/**
 *
 * Only available for general admins.
 * This is for the view single admin page and the company_admin_landing page, when the download csv of employees button is clicked.
 */
$(document).ready(() => {
  if ($('body.admin').length || $('body.company_admin').length || $('body.platform_admin').length) {
    $(".csv-download").click(function () {
      // Show widget
      $(".widget-overlay").removeClass('hide');

      // The total number of employees associated with this employer
      const domainEmployeesTable = $('#view_all_site_employees');
      const employerEmployeesTable = $('#view_employees');
      const table = domainEmployeesTable.length ? domainEmployeesTable : employerEmployeesTable;
      const totalEmployees = table.DataTable().rows().count();

      /**
      * Request CSV data from the server, beginning with offset. That is,
      * we will retrieve CSV data for this employer's employees starting
      * at the employee indicated by offset.
      *
      * @param [Integer] offset - the number of employees that have been uploaded so far.
      */
      const makeRequest = (offset) => {
        // Update the widget text to indicate our percent of the way done
        const percent = (100.0 * offset / totalEmployees).toFixed(1);
        $('.widget-text').text(`Processing... (${percent}%)`);

        // AJAX call for employer csv data
        $.ajax({
          url: $(".csv-download").find("a.btn-floating").attr('ajax-ref') + `?offset=${offset}`,
          type: 'GET',
          success: callback,
          error: function (error) {
            $('.widget-overlay').hide();
            Materialize.toast($('#i18n').data()['errorOccurred'], 4000);
            console.error(error);
          }
        });
      };

      // File segments will accumulate the text content of the CSV file
      // we will generate.
      let fileContent = '';

      // The next offset to use. This will be increased every time we
      // receive new results.
      let next = 0;

	    /**
	     * The callback that is invoked when we receive a response from the
	     * server. results will be a JSON object with properties "employee_count"
	     * (the number of employees in this batch) and "csv_text" (the text of
	     * the CSV file, including a header, for this one batch).
	     *
	     * @param [Hash] results - the hash of employee data for the csv.
	     */
      const callback = (results) => {
        if (results && results.employee_count) {
          let rows = results.csv_text.split('\n');
          next += results.employee_count;

          // If this is not the first batch, remove the header
          if (fileContent.length) rows = rows.slice(1);
          fileContent += rows.join('\n');

          // Make the next request
          makeRequest(next);
        } else {
          // hide widget
          $(".widget-overlay").addClass('hide');

          // Save csv file
          var d = new Date();
          var blob = new Blob([fileContent], {type: "text/plain;charset=utf-8"});
          saveAs(blob, d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate() + "_employees.csv");
        }
      };

      // Make the first request
      makeRequest(0);
    });
  }
});
