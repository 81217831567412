import $ from 'jquery';

/**
 * Hides and shows the default value field for the create an
 * additional question form depending on what question_type is
 * selected.
 * Only available for general admins.
 */
$(document).ready(() => {
	if ($('body.employers,body.platform_admin.new_question').length) {

		var choice_number = 1;

		$('#add_dropdown_choice').click(function(){
			var input_choice = $('<input/>')
				.attr("id", "choice_"+choice_number)
				.attr("name", "choice_"+choice_number)
				.attr("type", "text")
				.attr("placeholder", "Choice "+choice_number)
				.attr("required", true)
				.attr("size", 150)
				.addClass("form-control");
			var label = $('<label/>')
				.attr("id", "choice_"+choice_number+"_label")
				.attr("for", "choice_"+choice_number)
				.text("Choice "+choice_number);
			$('#dropdown_choices .col').append(label);
			$('#dropdown_choices .col').append(input_choice);
			choice_number++;
		});

		$('#remove_dropdown_choice').click(function(){
			if(choice_number > 1) {
				$("#choice_" + (choice_number - 1)).remove();
				choice_number--;
			}
		});

		var $question_type = $('#question_type');
		$question_type.change(() => {
			if ($question_type.val() == "bool") {
				$('#text_default_row').addClass('hide');
				$('#required_row').addClass('hide');
				$('#dropdown_actions').addClass('hide');
				$('#dropdown_choices').addClass('hide');
				$('#drop_down_multiselect').addClass('hide');
			} else if ($question_type.val() == "text") {
				$('#required_row').removeClass('hide');
				$('#text_default_row').removeClass('hide');
				$('#dropdown_actions').addClass('hide');
				$('#dropdown_choices').addClass('hide');
				$('#drop_down_multiselect').addClass('hide');
			} else if ($question_type.val() == "dropdown") {
				$('#text_default_row').addClass('hide');
				$('#required_row').addClass('hide');
				$('#dropdown_actions').removeClass('hide');
				$('#dropdown_choices').removeClass('hide');
				$('#drop_down_multiselect').removeClass('hide');
			} else if ($question_type.val() == "date") {
				$('#text_default_row').addClass('hide');
				$('#required_row').removeClass('hide');
				$('#dropdown_actions').addClass('hide');
				$('#dropdown_choices').addClass('hide');
			} else if ($question_type.val() == "") {
				$('#text_default_row').addClass('hide');
				$('#required_row').addClass('hide');
				$('#dropdown_actions').addClass('hide');
				$('#dropdown_choices').addClass('hide');
				$('#drop_down_multiselect').addClass('hide');
			}
		});
	}
});



