import IEFixes from './ie-fixes';
import * as d3 from 'd3';

/**
 * A Core Score Dial is an SVG-based dial that displays the user's Core Score.
 *
 * @example
 *    new CoreScoreDial('.core-score-dial').render();
 *
 * @param elementToSelect
 * A selection string or other d3-selectable object that identifies the single
 * element to which to append the Core Score Dial.
 *
 * @constructor
 */
function CoreScoreDial(elementToSelect, level) {
  const translationMap = $('#i18n').data();

  // Convenient trick to make sure we're always able to access the "correct" this
  let that = this;

  /** The element to which the core score dial SVG is added.
   *
   * @type {d3.selection}
   */
  this.elem = d3.select(elementToSelect);

  /**
   * The SVG that contains the elements that make up the core score dial.
   *
   * @type {d3.selection}
   */
  this.svg = null;

  /**
   * The pointer element (the black triangle that moves).
   *
   * @type {d3.selection}
   */
  this.pointer = null;

  /**
   * The minimum possible core score. This is computed from segments inside of
   * the render function.
   *
   * @type {int}
   */
  this.minScore = null;

  /**
   * The maximum possible core score. This is computed from segments inside of
   * the render function.
   *
   * @type {int}
   */
  this.maxScore = null;

  /**
   * Geometry constants.
   *
   * @type {{width: number, height: number, fractionalVerticalPadding: number, segmentPadding: number, arcOuterRadius: number, arcInnerRadius: number, tickLabelRadius: number, segmentLabelRadius: number, scoreTextY: number, rankIconY: number, rankTextY: number, pointerWidth: number}}
   */
  this.geom = {
    // The width of the dial
    width:  320,

    // The height of the dial
    height: 130,

    // The fractional amount by which to pad the height of the core score dial
    fractionalVerticalPadding: 0.075,

    segmentPadding: 0.01,

    // Outer radius of the main arc
    arcOuterRadius: 95,

    // Inner radius of the main arc
    arcInnerRadius: 72,

    // Radius along which to place tick labels
    tickLabelRadius: 110,

    // Radius along which to place segment labels (Good, Top, etc.)
    segmentLabelRadius: 110,

    // The Y-coordinate of the score text (relative to the top of the SVG)
    scoreTextY: 100,

    // The Y-coordinate of the rank icon (relative to the top of the SVG)
    rankIconY: 120,

    // The Y-coordinate of the rank text (relative to the top of the SVG)
    rankTextY: 130,

    // The Y-coordinate of the "Please retake..." text that is displayed when
    // the user fails the assessment (when the dial is in fail mode)
    failTextY: 88,

    // The line height of the fail text (in pixels)
    failTextLineHeight: 8,

    // The width (in pixels) of the pointer triangle
    pointerWidth: 16
  };

  /**
   * The segments of the core score dial. Each segment defines a score range
   * (the minimum and maximum score for the segment) and an angular range (the
   * beginning and end angle of the segment on the dial).
   *
   * @type {[*]}
   */
  this.segments = {
    entry: [
      { // The range of scores contained by this segment
        scoreRange: [574, 711],

        // The angle at which this arc starts and stops in the dial
        angleRange: [-90, 0],

        // The label to display above the segment in the dial
        segmentLabel: '',

        // The rank label to display to a user whose score falls in this range
        rankLabel: translationMap['skillUp'],

        // The icon to display to a user whose score falls in this range
        icon:  '\uf108',

        // The class to add to the segment label, rank label, and icon
        class: 'fail'
      },

      { scoreRange: [711, 731],
        angleRange: [0, 45],
        segmentLabel: translationMap['good'],
        rankLabel: translationMap['good'],
        icon:  '\uf058',
        class: 'goodE'
      },

      { scoreRange: [731, 775],
        angleRange: [45, 90],
        segmentLabel: translationMap['top'],
        rankLabel: translationMap['topPerformer'],
        icon:  '\uf005',
        class: 'topE'
      }
    ],
    advanced: [
      { scoreRange: [574, 775],
        angleRange: [-90, 0],
        segmentLabel: '',
        rankLabel: translationMap['skillUp'],
        icon:  '\uf108',
        class: 'fail'
      },
      { scoreRange: [775, 809],
        angleRange: [0, 45],
        segmentLabel: translationMap['good'],
        rankLabel: translationMap['good'],
        icon:  '\uf058',
        class: 'goodA'
      },

      { scoreRange: [809, 850],
        angleRange: [45, 90],
        segmentLabel: translationMap['top'],
        rankLabel: translationMap['topPerformer'],
        icon:  '\uf005',
        class: 'topA'
      }
    ]
  }[level];


  /**
   * Render the core score dial.
   */
  this.render = function() {
    let geom = that.geom;

    // Compute the min and max possible scores
    that.minScore = d3.min(that.segments, (d) => d.scoreRange[0]);
    that.maxScore = d3.max(that.segments, (d) => d.scoreRange[1]);

    // Create the SVG
    that.svg = that.elem
      .append('svg')
      .attr('class', 'rendered-core-score-dial')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${geom.width} ${geom.height}`);

    // On Internet Exploder, we have to set a constant height.
    IEFixes.fixSVGSize(that.svg, that.elem, geom.width, geom.height);

    // The height of the actual dial
    const innerHeight = geom.height * (1 - that.geom.fractionalVerticalPadding);

    // The group containing the dial
    let dialContainer = that.svg
      .append('g')
      .attr('class', 'dial-container')
      .attr('transform', `translate(${geom.width / 2.0}, ${innerHeight})`);

    appendArcs(dialContainer);
    appendTickLabels(dialContainer);
    appendSegmentLabels(dialContainer);
    appendPointer(dialContainer);
    appendScoreText(dialContainer);

    // Pull the score from the data attribute of this.elem, and update the score
    let score = that.elem.attr('data-corescore');

    // If score is 0 or not defined, we'll assume the user has not taken the
    // assessment; otherwise we'll displayed the score
    if (!score)
      that.notTaken();
    else
      that.updateScore(score);

    return this;
  };

  /**
   * Update the score displayed by this dial. This also causes the animations
   * to run, including counting up the score from 0 to the score, and slowly
   * moving the needle over.
   *
   * @param score {int}
   * The new core score to display.
   */
  this.updateScore = function(score) {
    if (!that.pointer) {
      console.error("Pointer not set. Have you called render?");
      return;
    }

    // Disable fail-mode if it is currently set
    that.svg.classed('fail-mode', false);
    that.elem.classed('fail-mode', false);

    // The tween function to use to animate the counting up of the score text
    // from the minimum score to the actual score. When the animation runs, d3
    // will repeatedly call the function returned by tweenText with increasing
    // values of t from 0 (when the animation starts) to 1 (when the animation
    // ends).
    const tweenText = function() {
      return (t) =>
        d3.select(this)
          .text( (that.minScore + t * (score - that.minScore)).toFixed());
    };

    // Animate the dial score text counting up from 0 to the score
    that.svg.select('text.dial-score-text')
      .transition()
      .duration(2000)
      .tween("text", tweenText);

    // The arc along which the pointer will move
    const arc = d3.arc()
      .outerRadius(that.geom.arcOuterRadius)
      .innerRadius(that.geom.arcInnerRadius);

    // Function to compute the transform that will position the pointer to
    // point at the provided angle
    const computeTransform = function(angle) {
      const radians = toRadians(angle);
      const centroid = arc.centroid(
        { startAngle: radians, endAngle:   radians } );
      return `translate(${centroid})rotate(${angle})`;
    };

    // The tween function to use for the pointer animation. This similarly
    // returns a function of t that will be called repeatedly by d3 with
    // increasing values of t from 0 to 1, and that function will return the
    // value of the transform attribute to set for the pointer.
    const tweenPointer = function() {
      // Where the pointer should start
      const startingAngle = convertScoreToAngle(that.minScore);
      // Where it should end up
      const finalAngle = convertScoreToAngle(score);
      // The angular velocity at which the pointer will move. There is an
      // implicit division by 1 here (t starts at 0 and ends at 1).
      const velocity = finalAngle - startingAngle;


      return (t) => {
        return computeTransform(startingAngle + t * velocity);
      };
    };

    // Animate the pointer moving to the correct score
    that.pointer
      .classed('hide', false)
      .attr('transform', computeTransform(convertScoreToAngle(that.minScore)))
      .transition()
      .duration(2000)
      .ease(d3.easeBounce)
      .attrTween("transform", tweenPointer);

    // Update the rank label and icon for this score
    updateRankLabel(score);

    // Fade in the rank icon and label
    that.svg.selectAll('text.dial-rank-icon,text.dial-rank-label')
      .style('opacity', 0.0)
      .transition()
      .delay(500)
      .duration(1500)
      .style('opacity', 1.0);

    return this;
  };

  /**
   * Display the Not Taken "fail-mode" dial. This dial is shown when the user's
   * Core Score is 0, indicating that the user has not attempted the assessment.
   *
   * Previously, this was called the "fail dial" and was rendered when the user
   * attempted and failed the advanced assessment while having not attempted
   * the entry assessment. Now, this mode is shown only when the user has not
   * attempted the particular assessment.
   */
  this.notTaken = function() {
    var translationMap = $('#i18n').data();

    // Adding this class is responsible for most of the visual changes. See the
    // svg.rendered-core-score-dial.fail-mode section of core-score-dail.scss
    that.svg.classed('fail-mode', true);
    that.elem.classed('fail-mode', true);

    that.svg.select('.dial-rank-icon').style({opacity: 0});

    // The text to display instead of a score when fail mode is active. Each
    // element in this array is a new line
    const failCaptionLines =
      [ translationMap['failCaptionOne'],
        translationMap['failCaptionTwo']
      ];

    // If the fail ext has previously been added, remove it
    that.svg.selectAll('g.dial-fail-text').remove();

    // Add a container svg:g to hold the fail text
    const failTextContainer = that.svg.select('g.dial-container')
      .append('g')
      .attr('class', 'dial-fail-text');

    // For each line in failCaptionLines
    failCaptionLines.forEach(function(text, i) {
      // Compute the center y for this line. These will increase by
      // failTextLineHeight each time through the loop
      const y = -that.geom.height + that.geom.failTextY +
        i  * that.geom.failTextLineHeight;

      // Add the text
      failTextContainer
        .append('text')
        .attr('class', 'dial-fail-text hide-on-medium-only')
        .attr('x', 0)
        .attr('y', y)
        .text(text)
    });

  };



  /*****************************************************************************
   * PRIVATE METHODS                                                           *
   *****************************************************************************/

  /**
   * Convert degrees to radians.
   */
  const toRadians = (deg) => deg * (Math.PI / 180);


  /**
   * Convert a score to the corresponding angle on the core score dial. That
   * is, the returned angle is the angle in which the pointer should point to
   * indicate the provided score.
   *
   * This is somewhat more complicated than one might expect, as the score
   * segments don't actually span the same angular distance. For example, the
   * first segment goes from 574 to 711 (137 points) and occupies an angular
   * distance from -90deg to -60 deg, while the second segment goes from 711 to
   * 731 (20 points) and occupies an angular distance from -60deg to -30deg.
   *
   * To solve this, we simply search through all of the segments and find one
   * with a score range that the provided score fits in. Then we normalize the
   * provided score to within the score range of the segment, and convert it to
   * an angle with the angle range of the segment.
   *
   * @param score
   * The score to convert
   *
   * @return {number}
   * The angle, in degrees, at which the pointer should point to indicate the
   * provided score.
   */
  let convertScoreToAngle = function(score) {
    // Find the segment into which this score fits. Note that a score could
    // potentially fall within more than one score range; however, for now
    // we are ignoring that as it only occurs at the edges of segments where
    // the angle would be the same regardless of what segment we pick. That's
    // not the case when we need to decide what rank to assign (see updateRankLabel).
    const segment = that.segments.find((segment) =>
      score >= segment.scoreRange[0] && score <= segment.scoreRange[1]
    );

    // We didn't find a segment.
    if (!segment) return -90.0;

    // Normalize the score to within the score range of this segment
    const normalizedScore = (score - segment.scoreRange[0]) /
      (segment.scoreRange[1] - segment.scoreRange[0]);

    // Then scale it to the angle range of this segment
    return normalizedScore *
      (segment.angleRange[1] - segment.angleRange[0]) +
      segment.angleRange[0];
  };


  /**
   * Append the colored arcs that make up the dial.
   *
   * @param g
   * The svg:g to which to append the arcs.
   */
  const appendArcs = function(g) {
    // We are going to decrease the ending angle for all segments except the
    // last one by that.geom.segmentPadding to create a bit of padding
    let determinePadding = (segment) =>
      segment.scoreRange[1] < that.maxScore ? that.geom.segmentPadding : 0;

    // Convert the segments to d3.arc generators
    let arcs = that.segments.map((segment) =>
      d3.arc()
        .outerRadius(that.geom.arcOuterRadius)
        .innerRadius(that.geom.arcInnerRadius)
        .startAngle(toRadians(segment.angleRange[0]))
        .endAngle(toRadians(segment.angleRange[1]) - determinePadding(segment))
    );

    // Append a path for each arc
    arcs.forEach((arc, i) =>
      g.append('path')
        .attr('class', `arc segment ${that.segments[i].class}`)
        .attr('d', arc)
    );
  };

  /**
   * Append the tick labels to the dial. These are the small numbers at the
   * breakpoints between the segments.
   *
   * @param g
   * The svg:g to which to append the arcs.
   */
  const appendTickLabels = function(g) {
    // tickLabels is an array of unique score values that are either a start
    // or end of a segment.
    const tickLabels = Array.from(new Set(
      // This goofy syntax is equivalent to a flatMap.
      [].concat.apply([], that.segments.map((s) => s.scoreRange))
    ));

    // The arc along which to place tick labels
    const arc = d3.arc()
      .outerRadius(that.geom.tickLabelRadius)
      .innerRadius(that.geom.tickLabelRadius);

    // Compute the angles for each of the tick labels
    const data = tickLabels.map(function (score) {
      const angle = toRadians(convertScoreToAngle(score));
      return {
        score: score,
        startAngle: angle,
        endAngle: angle
      };
    });

    // Append the tick labels
    g.append('g')
      .attr('class', 'tick-labels')
      .selectAll('text.segment-tick-label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'segment-tick-label')
      .text((d) => d.score)
      .attr('transform', (d) => `translate(${arc.centroid(d)})`);
  };

  /**
   * Add the segment labels (Good, Top, etc.) along the outside of the main arc.
   *
   * @param g
   * The svg:g to which to add the segment labels.
   */
  const appendSegmentLabels = function(g) {
    // The arc along which the segment labels will be rendered
    const baseArc = d3.arc()
      .outerRadius(that.geom.segmentLabelRadius)
      .innerRadius(that.geom.segmentLabelRadius);

    // Function to find the centroid of a segment within the baseArc
    const findCentroid = (segment) =>
      baseArc
        .centroid({
          startAngle: toRadians(segment.angleRange[0]),
          endAngle: toRadians(segment.angleRange[1])
        });

    // Add a g.segment-labels containing the text.segment-label elements for
    // each segment
    g.append('g')
      .attr('class', 'segment-labels')
      .selectAll('text.segment-label')
      .data(that.segments)
      .enter()
      .append('text')
      .attr('class', (d) => `segment-label ${d.class}`)
      .text((d) => d.segmentLabel)
      .attr('transform', (d) => `translate(${findCentroid(d)})`)

  };

  /**
   * Append the pointer (the small triangle that points to the score) to the
   * SVG.
   *
   * @param g
   * The svg:g to which to append the arcs.
   */
  const appendPointer = function(g) {
    // When not rotated, the triangle will point straight up. This is its height.
    const pointerHeight = that.geom.arcOuterRadius - that.geom.arcInnerRadius;
    // The width of the triangle
    const pointerWidth = that.geom.pointerWidth;

    // The triangle will be centered in the path, such that placing it at (0,0)
    // will position the centered of the triangle at (0,0)
    const top = -pointerHeight / 2.0;
    const bottom = pointerHeight / 2.0;

    // The coordinates
    const left = { x: -pointerWidth / 2.0, y: bottom};
    const middle = { x: 0, y: top };
    const right = { x: pointerWidth / 2.0, y: bottom};

    // Create the path
    that.pointer = g.append('path')
      .attr('class', 'pointer hide')
      .attr('d', `M ${left.x} ${left.y} L ${middle.x} ${middle.y} L ${right.x} ${right.y} Z`);
  };

  /**
   * Append the label that indicates the user's core score, the rank label, and
   * the rank icon elements to the svg.
   *
   * @param g
   * The svg:g to which to append the arcs.
   */
  const appendScoreText = function(g) {
    // Add the big score text object
    g.append('text')
      .attr('class', 'dial-score-text')
      .attr('x', 0)
      .attr('y', -that.geom.height + that.geom.scoreTextY)
      .text('574');

    // Add the rank icon
    g.append('text')
      .attr('class', 'dial-rank-icon')
      .attr('x', 0)
      .attr('y', -that.geom.height + that.geom.rankIconY)
      .style('font-family', 'FontAwesome');

    // Add the rank label
    g.append('text')
      .attr('class', 'dial-rank-label')
      .attr('x', 0)
      .attr('y', -that.geom.height + that.geom.rankTextY);
  };


  /**
   * Update the rank label and rank icon for the provided score.
   *
   * @param score
   * The Core Score to display.
   */
  const updateRankLabel = function(score) {
    // Find the segment in which the score falls
    const segment = that.segments.find((segment) =>
      segment.scoreRange[0] <= score &&
        (segment.scoreRange[1] > score ||
          (segment.scoreRange[1] === that.maxScore && score === that.maxScore))
    );

    // If the score doesn't fall into any segment, bail
    if (!segment) {
      console.error(`Unable to find a segment for the score: ${score}`)
      return;
    }

    // Update the rank icon
    that.svg.selectAll('text.dial-rank-icon')
      .text(segment.icon)
      .attr('class', `dial-rank-icon ${segment.class}`);

    // Update the rank label
    that.svg.selectAll('text.dial-rank-label')
      .text(segment.rankLabel)
      .attr('class', `dial-rank-label ${segment.class}`);
  };
}

export default CoreScoreDial;
