import $ from 'jquery';
import EmployeeTableHelper from "../shared/employee_table_helpers";

$(document).ready(function() {
  if ($('body.admin.view_single_employer').length) {
    let data = $('#view_employees').data();
    let is_super_admin = data.superAdmin;
    let employer_id = data.employerId;

    EmployeeTableHelper.constructUsersTable('#view_employees', `/view_employees/employer/${employer_id}`, is_super_admin);
  }
  if ($('body.admin.view_single_platform').length) {
    let data = $('#view_employees').data();
    let platform_id = data.platformId;

    EmployeeTableHelper.constructUsersTable('#view_employees', `/view_employees/platform/${platform_id}`, false);
  }
    // For admin view single employer page company admins data table
    $('#view_company_admins').DataTable({
        columnDefs: [
            {className: "dt-center", targets: "_all"}
        ],
        order: [[ 0, 'asc' ],[1, 'asc']],
        pageLength: 25
    });

    // For admin view single employer page positions data table
    $('#view_positions').DataTable({
        columnDefs: [
            {className: "dt-center", targets: "_all"}
        ],
        order: [[ 0, 'asc' ]],
        pageLength: 25
    });

    // For admin view single employer page store numbers data table
    $('#view_store_numbers').DataTable({
        columnDefs: [
            {className: "dt-center", targets: "_all"}
        ],
        order: [[ 0, 'asc' ]],
        pageLength: 25
    });

    // For admin view single employer page questions data table
    $('#view_questions').DataTable({
        columnDefs: [
            {className: "dt-center", targets: "_all"}
        ],
        order: [[ 0, 'asc' ]],
        pageLength: 25
    });
});