import BreakoutBarChart from '../charts/breakout-bar-chart';
import CoreScoreDial from '../charts/core-score-dial';
import $ from 'jquery';
import * as d3 from 'd3';

$(document).ready(function () {
    if (!$('body.score_report').length) return;

    // Make the accordions collapsible
    $('.collapsible').collapsible();

    // When someone clicks a link inside of a collapsible header, don't expand
    // the collapsible
    $('.collapsible-header').on('click', (e) => {
        if ($(e.target).is('a')) e.stopPropagation()
    });

    // Enable modal popups
    $('.modal').modal({
        dismissible: true,
        opacity: .5
    });

    // Survey modal pops up at 30 sec for first time logged in employees.
    setTimeout(function () {
        $('#survey_modal').modal('open');
    }, 30000);

    // Finish training modal pops up after 5 seconds.
    setTimeout(function () {
        $('#finish_training_modal').modal('open');
    }, 5000);

    // Completed training modal pops up after 1 second
    setTimeout(function () {
        $('#completed_training_modal').modal('open');
    }, 1000);

    // Add the breakout bar charts
    d3.selectAll('.csb-breakout-bar-chart')
        .each(function () {
            new BreakoutBarChart(this).render()
        });

    // Create and render the Core Score Dials.
    new CoreScoreDial('.core-score-dial.entry-dial', 'entry').render();
    new CoreScoreDial('.core-score-dial.advanced-dial', 'advanced').render();


    // Make the entry and advanced tabs toggle the visible report
    $('#entry_tab,#advanced_tab').click(function () {
        // If the tab we clicked was already selected or is disabled, don't do anything.
        if ($(this).hasClass('selected') || $(this).hasClass('disabled')) return;

        // Toggle the selected/unselected classes of the tabs themselves
        $('#entry_tab,#advanced_tab')
            .toggleClass('selected')
            .toggleClass('unselected');

        // Toggle the visibility of each report
        $('#entry_report,#advanced_report')
            .toggleClass('hide');
    });

});