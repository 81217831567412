import $ from 'jquery';

$(document).ready(function() {
    const $questionsIndex = $('#assessment-questions-index');

    if (!$questionsIndex.length) return;

    $('#assessment-questions-table').DataTable({
        pageLength: 25
    });
});
